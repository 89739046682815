import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { connectStats, connectSearchBox, connectInfiniteHits, connectVoiceSearch, connectClearRefinements, connectNumericMenu, connectToggleRefinement } from 'instantsearch.js/es/connectors';
import { index, refinementList, currentRefinements, numericMenu, toggleRefinement } from 'instantsearch.js/es/widgets';
import { highlight } from 'instantsearch.js/es/helpers';
import 'jquery-throttle-debounce/jquery.ba-throttle-debounce';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import debounce from 'lodash.debounce';
import './styles/ais-gs.css';

let debug = false;
let cacheEnabled = true;
let showCatImage = true;
let currentdata = [];
const autoInfinite = false && "IntersectionObserver" in window;
const algoliaSearchDelay = 250;
const ais_lang = ($('html').attr('lang') || '').length > 0 ? $('html').attr('lang').split('-')[0] : 'en';
const currDate = (new Date()).toISOString().substring(0, 10).replace(/-/g, ''); //data in formato numerico yyyymmdd
let currDateInt = 99991231;
if (currDate) {
    currDateInt = parseInt(currDate);
    if (isNaN(currDateInt)) {
        currDateInt = 99991231;
    }
}

const _responsecache = {};
//const max_cache_size = 10;

const serialize = (key) => {
    if (typeof key !== 'string') {
        return (JSON.stringify(key));
    }
    return (key);
};

const cache_get = (key) => {
    var value = _responsecache[serialize(key)];
    return (value && value.data);
};




const cache_put = (key, value) => {
    _responsecache[serialize(key)] = { data: value };
};

const sendEventDebounced = debounce((uiState) => {
    //ci ho messo un paletto per evitare che pushi anche i filtri. Serve con Google Tag Manager
    if (!uiState[ais_gs_cfg.get().indexProd].refinementList) {
        var searchtext = uiState[ais_gs_cfg.get().indexCat].query;
        var page = window.location.pathname + '?txtkeyword=' + searchtext;
        window.ga('send', 'pageView', page);
    }
}, 1000);
const analyticsMiddleware = () => {
    return {
        onStateChange({ uiState }) {
            sendEventDebounced(uiState);
        },
        subscribe() { },
        unsubscribe() { },
    }
}
let _curr_ais_gs_cfg;
const ais_gs_cfg = {
    get: (lang) => {
        if (!lang) lang = ais_lang;

        if (!_curr_ais_gs_cfg) {
            let l = lang;
            if (!["it", "en", "ru", "de", "fr", "pl", "el", "hr", "es"].includes(lang) || !ais_gs_cfg[lang]) {
                l = "en";
            }
            _curr_ais_gs_cfg = ais_gs_cfg[lang];
        }
        return _curr_ais_gs_cfg;
    },
    "it": {
        "indexProd": "dev_PROD_ITA",
        "indexCat": "dev_CAT_ITA",
        "voiceSearchLang": "it-IT",
    },
    "en": {
        "indexProd": "dev_PROD_ENG",
        "indexCat": "dev_CAT_ENG",
        "voiceSearchLang": "en-US",
    },
    "ru": {
        "indexProd": "dev_PROD_RUS",
        "indexCat": "dev_CAT_RUS",
        "voiceSearchLang": "ru-RU",
    },
    "de": {
        "indexProd": "dev_PROD_DEU",
        "indexCat": "dev_CAT_DEU",
        "voiceSearchLang": "de-DE",
    },
    "fr": {
        "indexProd": "dev_PROD_FRA",
        "indexCat": "dev_CAT_FRA",
        "voiceSearchLang": "fr-FR",
    },
    "pl": {
        "indexProd": "dev_PROD_POL",
        "indexCat": "dev_CAT_POL",
        "voiceSearchLang": "pl-PL",
    },
    "el": {
        "indexProd": "dev_PROD_ELL",
        "indexCat": "dev_CAT_ELL",
        "voiceSearchLang": "el-GR",
    },
    "hr": {
        "indexProd": "dev_PROD_HRV",
        "indexCat": "dev_CAT_HRV",
        "voiceSearchLang": "hr-HR",
    },
    "es": {
        "indexProd": "dev_PROD_SPA",
        "indexCat": "dev_CAT_SPA",
        "voiceSearchLang": "es-ES",
    },
};


//const useFullscreenAis = () => {
//	return $('#useFullscreenAis').css("display") != "none";
//};

const resetProdHitsScroll = () => {
    $('.col-prods .ais-gs-col-container').scrollTop(0);
};

const resetCatHitsScroll = () => {
    $('.col-cats .ais-gs-col-container').scrollTop(0);
};

const resetFacetsScroll = () => {
    $('.col-facets .ais-gs-col-container').scrollTop(0);
};

const isTouchDevice = () => {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = (query) => {
        return window.matchMedia(query).matches;
    };

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
};

const isFullScreenAis = () => {
    var smallScreen = window.matchMedia("(min-width: 1200px)");
    const fs = (!smallScreen.matches || (document.location.pathname.match(/[^\/]+$/) != null && document.location.pathname.match(/[^\/]+$/)[0] == 'advancedSearch.aspx'));
    $('body').attr('data-ais-fs', fs ? '1' : '0');
    return fs;
};

const isSinglePanelAis = () => {
    const sp = $('#useSinglePanelAis').css("display") !== "none";
    $('body').attr('data-ais-fs-singlepanel', sp ? '1' : '0');
    return sp;
};

// lngtag_ais_gs
const getText = (labelcode) => {
    return (lngtag_ais_gs !== undefined && lngtag_ais_gs[labelcode] !== undefined)
        ? lngtag_ais_gs[labelcode]
        : labelcode;
};
const getPermissions = (permissioncode) => {
    return (permissions_ais_gs !== undefined && permissions_ais_gs[permissioncode] !== undefined)
        ? permissions_ais_gs[permissioncode] === 'True'
        : false;
};
const toggleFacetPanel = () => {
    showProductsPanel();
    if ($("#ais-gs-filter").attr("data-switch") === "on") {
        // spengo il pulsante e chiudo il pannello
        showFacetPanel(false);
    } else {
        // attivo il pulsante e visualizzo il pannello
        showFacetPanel(true);
    }
};

const showFacetPanel = (show) => {
    $("#ais-gs-filter").attr("data-switch", show ? "on" : "off");
    $(".col-facets").attr("data-expanded", show ? "1" : "0");
};

const showCategoriesPanel = () => {
    $("body").attr("data-ais-fs-activepanel", "cats");
    showFacetPanel(false);
};

const showProductsPanel = () => {
    $('body').attr('data-ais-fs-activepanel', 'prods');
};

$(() => {
    var cssgridsupported = typeof document.createElement("div").style.grid === "string";
    hideAlgoliaSearchPanel();
    $('.ais-gs-panel-body').attr("data-cssgrid-supported", cssgridsupported ? "1" : "0");

    $("body").on("click", "#ais-gs-filter", toggleFacetPanel);
    $("body").on("click", "#ais-gs-closefilter", toggleFacetPanel);
    $("body").on("click", bAisFsCloseClientID, hideAlgoliaSearchPanel);
    $("body").on("click", ".col-cats .ais-gs-col-swipe", showProductsPanel);
    $("body").on("click", ".col-prods .ais-gs-col-swipe", showCategoriesPanel);

    $("body").attr("data-ais-touchdevice", isTouchDevice() ? "1" : "0");

    $(txtkeywordClientID).keypress(handleEnterKey);

    let is_hidden = isAlgoliaSearchHidden();
    let is_fs = isFullScreenAis();
    let is_singlepanel = isSinglePanelAis();

    if (is_singlepanel) {
        showProductsPanel();
    } else {
        showProductsPanel();
        showFacetPanel(true);
    }
});

const handleEnterKey = (evt) => {
    let searchTextIsNumeric = ($(txtkeywordClientID).val() || '').match("^[0-9][0-9.]*$"); // Il testo di ricerca è solo numeri o punti (codici articolo)

    let keycode = (evt.keyCode ? evt.keyCode : evt.which);
    // Se si preme invio e il testo cercato non è un codice articolo -> inibisco il tasto invio
    if (keycode === 13 && !searchTextIsNumeric) {
        evt.preventDefault();
        evt.stopPropagation();
        $(txtkeywordClientID).blur();
        $('.ais-gs-panel-body').focus();
        return false;
    }
};

//--- FACET main

const refreshAppliedFiltersCounter = (facetSelector) => {
    let filterCount = $(facetSelector + " .facet-current-refinements span.ais-CurrentRefinements-category").length;
    if (filterCount === 0) {
        $(facetSelector + " .facet-filter-count-expand").attr("disabled", "disabled");
        //collasso
        $(facetSelector + " .facet-filter-count-arrow").removeClass("fa-caret-up").addClass("fa-caret-down");
        $(facetSelector + " .facet-current-refinements").css("display", "none");
    } else {
        $(facetSelector + " .facet-filter-count-expand").removeAttr("disabled");
    }
    $(facetSelector + " .facet-filter-count-value").text(filterCount);

    return filterCount;
};

const refreshAppliedFiltersCounters = () => {
    // Reset scroll prodotti
    //resetProdHitsScroll();

    var oldCountTxt = $(".ais-gs-facet-countapplied").text();
    var oldCount = 0;
    if (oldCountTxt) {
        oldCount = parseInt(oldCountTxt);
        if (isNaN(oldCount)) {
            oldCount = 0;
        }
    }

    // CATEGORIES
    const filterCount_category = refreshAppliedFiltersCounter("#facet-category");

    // BRANDS
    const filterCount_brand = refreshAppliedFiltersCounter("#facet-brand");

    // NEW PRODUCTS
    const filterCount_new = $('input[type="checkbox"][name="new_exp_date"]:checked').length;
    // OUTLET
    const filterCount_outlet = $('input[type="checkbox"][name="is_outlet"]:checked').length;

    // TOTAL
    let filterCount_total = filterCount_category + filterCount_brand + filterCount_new + filterCount_outlet;
    $(".ais-gs-facet-countapplied").text(filterCount_total);

    if (oldCount !== filterCount_total) {
        // Reset scroll prodotti
        resetProdHitsScroll();
    }
};

const toggleCurrentRefinementExpansion = (facetSelector) => {
    if (!$(facetSelector + " .facet-filter-count-expand").attr("disabled")) {
        if (isCurrentRefinementHidden(facetSelector)) {
            // espando
            $(facetSelector + " .facet-filter-count-arrow").removeClass("fa-caret-down").addClass("fa-caret-up");
            $(facetSelector + " .facet-current-refinements").css("display", "block");
        } else {
            // collasso
            $(facetSelector + " .facet-filter-count-arrow").removeClass("fa-caret-up").addClass("fa-caret-down");
            $(facetSelector + " .facet-current-refinements").css("display", "none");
        }
    }
};

function isCurrentRefinementHidden(facetSelector) {
    return $(facetSelector + " .facet-current-refinements").css("display").toLowerCase() === 'none';
}

//$("#facets").on('DOMSubtreeModified', ".facet-current-refinements", refreshAppliedFiltersCounters);


const checkFacetVisibility = (facetSelector) => {
    const hasFacets = $(facetSelector + " .facet-list ul li").length > 0;
    const hasSelectedFactes = $(facetSelector + " .facet-current-refinements span.ais-CurrentRefinements-category").length > 0;
    //$(facetSelector).css("display", (hasFacets || hasSelectedFactes) ? "block" : "none");
};

// ---------

//--- FACET category
const toggleCategoryCurrentRefinementExpansion = () => {
    toggleCurrentRefinementExpansion("#facet-category");
};
const checkFacetCategoryVisibility = () => {
    checkFacetVisibility("#facet-category");
};

$("#facet-category .facet-filter-count").on("click", ".facet-filter-count-expand", toggleCategoryCurrentRefinementExpansion);
//$('#facet-category').on('DOMSubtreeModified', ".facet-list", checkFacetCategoryVisibility);

// ---------

//--- FACET brand
const toggleBrandCurrentRefinementExpansion = () => {
    toggleCurrentRefinementExpansion("#facet-brand");
};
const checkFacetBrandVisibility = () => {
    checkFacetVisibility("#facet-brand");
};


$("#facet-brand .facet-filter-count").on("click", ".facet-filter-count-expand", toggleBrandCurrentRefinementExpansion);
//$('#facet-brand').on('DOMSubtreeModified', ".facet-list", checkFacetBrandVisibility);

// ------


//DEBUG - INIZIO

let gsstats;
let gsalgoliapanel;
let gscontainer;
let gsinputbox;
let gsalgoliamaintop;
let gsalgoliamaincontainer;

$(() => {
    if (!debug) return;
    gsalgoliapanel = $('.ais-gs-panel-body');
    gscontainer = $('div.container');
    gsinputbox = $(txtkeywordClientID);
    gsalgoliamaintop = $('.ais-gs-maintop');
    gsalgoliamaincontainer = $('.ais-gs-maincontainer');

    let mydiv = document.createElement('div');

    $('body')[0].appendChild(mydiv);
    gsstats = $(mydiv);
    gsstats.css({ 'z-index': '999999', 'position': 'fixed', 'left': '0', 'bottom': '0', 'width': '300px', 'height': '600px', 'background-color': 'rgba(220,255,220,0.7)', 'border': '1px solid black' });


    printGsStats();
});

const printGsStats = () => {
    setTimeout(() => {
        if (gsstats && gsalgoliapanel) {
            let s =
                `
<b>bodyScrollLocked: ${(bodyScrollLocked || false)}</b><br/>

<b>window</b><br/>
height: ${$(window).height()} <br/>
width: ${$(window).width()} <br/>
scrollTop: ${$(window).scrollTop()} <br/>

<b>.col-prods .ais-gs-col-container</b><br/>
scrollTop: ${$('.col-prods .ais-gs-col-container').scrollTop()} <br/>

<b>.col-cats .ais-gs-col-container</b><br/>
scrollTop: ${$('.col-cats .ais-gs-col-container').scrollTop()} <br/>

<b>.col-facets .ais-gs-col-container</b><br/>
scrollTop: ${$('.col-facets .ais-gs-col-container').scrollTop()} <br/>

<b>.col-needmorechar</b><br/>
scrollTop: ${$('.col-needmorechar').scrollTop()} <br/>

`;

            //<b>gsinputbox</b><br/>
            //offset.left: ${gsinputbox.offset().left} <br/>
            //offset.top: ${gsinputbox.offset().top} <br/>
            //outerWidth: ${gsinputbox.outerWidth()} <br/>
            //outerHeight: ${gsinputbox.outerHeight()} <br/>

            //<b>gsalgoliapanel</b><br/>
            //offset.left: ${gsalgoliapanel.offset().left} <br/>
            //offset.top: ${gsalgoliapanel.offset().top} <br/>
            //outerWidth: ${gsalgoliapanel.outerWidth()} <br/>
            //outerHeight: ${gsalgoliapanel.outerHeight()} <br/>

            //<b>gscontainer</b><br/>
            //outerWidth: ${gscontainer.outerWidth()} <br/>
            //outerHeight: ${gscontainer.outerHeight()} <br/>
            //width: ${gscontainer.width()} <br/>
            //height: ${gscontainer.height()} <br/>

            //<b>gsalgoliamaintop</b><br/>
            //outerWidth: ${gsalgoliamaintop.outerWidth()} <br/>
            //outerHeight: ${gsalgoliamaintop.outerHeight()} <br/>
            //width: ${gsalgoliamaintop.width()} <br/>
            //height: ${gsalgoliamaintop.height()} <br/>

            //<b>gsalgoliamaincontainer</b><br/>
            //outerWidth: ${gsalgoliamaincontainer.outerWidth()} <br/>
            //outerHeight: ${gsalgoliamaincontainer.outerHeight()} <br/>
            //width: ${gsalgoliamaincontainer.width()} <br/>
            //height: ${gsalgoliamaincontainer.height()} <br/>`;

            $(gsstats).html(s);
        }
        printGsStats();
    }, 100);
};

//DEBUG - FINE

$(window).on('resize', function () {
    resizeAlgoliaSearchPanel();
});

function resizeAlgoliaSearchPanel() {

    const $algoliapanel = $('.ais-gs-panel-body');

    const isFS = isFullScreenAis();

    if (!isAlgoliaSearchHidden()) {
        const $inputBox = $(txtkeywordClientID);
        const $pageContainer = $('div.container');
        const $algoliamaintop = $('.ais-gs-maintop');
        const $algoliacolcontainers = $('.ais-gs-col-container');
        $inputBox.focus();
        if (isFS) {
            lockBodyScroll(true);
            // Fullscreen - mobile
            $algoliapanel.outerWidth('');
            $algoliapanel.offset({ top: 50, left: 0 });
            $algoliapanel.outerHeight('');
            $algoliacolcontainers.each(function (index) {
                $(this).height($algoliapanel.height() - $(this).offset().top + $algoliapanel.offset().top);
            });
        } else {
            // Standard
            lockBodyScroll(false);
            $algoliapanel.outerWidth($pageContainer.outerWidth());

            $algoliapanel.offset({
                top: ($inputBox.offset().top + $inputBox.outerHeight() + 1),
                left: Math.max(0, $(window).width() - $algoliapanel.outerWidth()) / 2
            });

            let newHeight = $(window).height() - ($algoliapanel.offset().top + 20);
            // keep height between 400px and 800px
            newHeight = Math.max(newHeight, 400);
            newHeight = Math.min(newHeight, 800);
            $algoliapanel.outerHeight(newHeight);
            $algoliacolcontainers.each(function (index) {
                $(this).height($algoliapanel.height() - $(this).offset().top + $algoliapanel.offset().top);
            });
        }
    }
}

let bodyScrollLocked = false;
const lockBodyScroll = (lock) => {

    if (lock && !bodyScrollLocked) {
        bodyScrollLocked = true;

        disableBodyScroll(document.querySelector('.col-prods .ais-gs-col-container'));
        disableBodyScroll(document.querySelector('.col-cats .ais-gs-col-container'));
        disableBodyScroll(document.querySelector('.col-facets .ais-gs-col-container'));
        disableBodyScroll(document.querySelector('.col-needmorechar'));
    } else if (!lock) {
        clearAllBodyScrollLocks();
        bodyScrollLocked = false;
    }
};

function showAlgoliaSearchPanel() {
    if (isAlgoliaSearchHidden()) {
        var $algoliapanel = $('.ais-gs-panel-body');

        if (isFullScreenAis()) {
            $(window).scrollTop(0);
            $algoliapanel.show();
        } else {
            if ($(txtkeywordClientID).val().length >= 1) {
                $algoliapanel.show();
            }
        }
        //disableBodyScroll(document.querySelector('.ais-gs-panel-body'));

        $('body').attr('data-ais-isopened', '1');
        resizeAlgoliaSearchPanel();
    }
}

function hideAlgoliaSearchPanel() {
    $('.ais-gs-panel-body').hide();
    $('body').attr('data-ais-isopened', '0');
    //$(window).scroll();
    lockBodyScroll(false);
}

function isAlgoliaSearchHidden() {
    const hidden = $('.ais-gs-panel-body').css("display").toLowerCase() === 'none';
    $('body').attr('data-ais-isopened', hidden ? '0' : '1');
    return hidden;
}
/*   */
function pushDataLayer(event, hits) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': event,
        'ecommerce': {
            'item_list_id': 'algoliasearchresult',
            'item_list_name': 'Risultati Algolia',
            'items': hits.map((hit, ind) => {
                var item = {};
                item.item_id = hit.item_no;
                item.item_name = hit.name;
                item.index = ind;
                item.item_brand = hit.brand_name;
                item.item_list_id = 'algoliasearchresult';
                item.item_list_name = 'Risultati Algolia';
                return item;
            })
        }
    });
};
function clickhit() {
    if ($(this).data("elem")) {
        var hit = currentdata.find(x => x.objectID == $(this).data("elem"));

        if (hit) {
            if (!hit.is_master_item) {
                pushDataLayer('select_item', [hit]);
            }
            window.location.href = hit.link;
        }
    }
};
var lastRenderArgs;

const sentinelProd = document.createElement('div');
sentinelProd.setAttribute("class", "ais-gs-sentinel");

const infiniteHitsProd = connectInfiniteHits(
    (renderArgs, isFirstRender) => {
        const { hits, showMore, widgetParams, isFirstPage, isLastPage } = renderArgs;
        const { container } = widgetParams;

        lastRenderArgs = renderArgs;

        if (isFirstRender) {
            const contdiv = document.createElement('div');
            contdiv.setAttribute("class", "ais-gs-hits-cont");
            container.appendChild(contdiv);
            if (autoInfinite) {
                const observer = new IntersectionObserver(entries => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting && !lastRenderArgs.isLastPage) {
                            showMore();
                        }
                    });
                });

                observer.observe(sentinelProd);
            } else {
                const button = document.createElement('span');
                button.className = "noselect spanbutton";
                button.innerHTML = '<i class="fa fa-chevron-down"></i>' + getText('_GS_SEARCH_SHOWMORE');

                button.addEventListener('click', () => {
                    showMore();
                });
                container.appendChild(button);
            }

            return;
        }

        //if (isFirstPage) {
        //	$(container).scrollTop(0);
        //}


        //var currDate = (new Date()).toISOString().substring(0, 10).replace(/-/g, ''); //data in formato numerico yyyymmdd

        if (!autoInfinite) {
            if (isLastPage) {
                $(container).children('span.spanbutton').addClass("disabled");
            } else {
                $(container).children('span.spanbutton').removeClass("disabled");
            }
        }
        var newArray = hits;
        if (!getPermissions("VIEW_OUTLET")) {
            newArray = hits.filter(function (el) {
                return !el.is_outlet || el.is_outlet != 1;
            });
        }
        pushDataLayer('view_item_list', newArray.filter(function (el) {
            return !el.is_master_item;
        }));
        currentdata = newArray;
        let path = window.location.pathname;
        let page = path.split("/").pop();
        $(container).children('div.ais-gs-hits-cont').html(newArray
            .map(
                hit =>
                    `<div class="ais-gs-hit">
	<a class="ais-gs-hit-link" data-elem="${hit.objectID}" href="javascript:void(0);">
		${(hit.new_exp_date && hit.new_exp_date >= currDate)
                        ? '<img class="tag" src="/images/new-flag.png"/>'
                        : ''}
        ${(hit.is_outlet && hit.is_outlet == 1)
                        ? '<img class="tag" src="/images/outlet-flag.png"/>'
                        : ''}
		<div class="ais-gs-image-attribute"><img src="${(hit.img_url && hit.img_url.startsWith('/SupplyImages/')) ? '' : '/SupplyImages/'}${hit.img_url}"/></div>
		<div class="ais-gs-hit-content">
			<span class="ais-gs-primary-attribute">${instantsearch.highlight({ attribute: 'title', hit })}</span>
			${(!hit.is_master_item && (hit.item_no || '').length > 0)
                        ? ('<span class="ais-gs-tertiary-attribute">' + instantsearch.highlight({ attribute: 'item_no', hit }) + '</span>')
                        : ''}
			<span class="ais-gs-secondary-attribute ${hit.is_master_item ? 'max-3-rows' : 'max-2-rows'}">
            ${(page != 'advancedSearch.aspx')
                        ? (instantsearch.highlight({ attribute: 'description', hit }))
                        : ((hit.catalog_page_no != 0)
                            ? 'p. ' + (hit.catalog_page_no)
                            : instantsearch.highlight({ attribute: 'description', hit }))}</span>
			<div class="clearfix"></div>
		</div>
	</a>
</div>`)
            .join(''));

        $('.ais-gs-hit-link').on('click', clickhit);

        // Spostare su nel first render?
        if (autoInfinite) {
            container.appendChild(sentinelProd);
        }
        var hashValue = window.location.hash;
        // Rimuovi il carattere "#" se presente
        hashValue = hashValue.substring(1);
        if (hashValue) {
            if ($("input[value='" + hashValue.toUpperCase() + "']") && $("input[value='" + hashValue.toUpperCase() + "']").prop('checked') == false) {
                $("input[value='" + hashValue.toUpperCase() + "']").closest('li').click();
                history.pushState("", document.title, window.location.pathname
                    + window.location.search);
            }
        }
    }
);

const sentinelCat = document.createElement('div');
sentinelCat.setAttribute("class", "ais-gs-sentinel");

const infiniteHitsCat = connectInfiniteHits(
    (renderArgs, isFirstRender) => {
        const { hits, showMore, widgetParams, isLastPage } = renderArgs;
        const { container } = widgetParams;

        lastRenderArgs = renderArgs;

        if (isFirstRender) {
            const contdiv = document.createElement('div');
            contdiv.setAttribute("class", "ais-gs-hits-cont");
            container.appendChild(contdiv);
            if (autoInfinite) {
                const observer = new IntersectionObserver(entries => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting && !lastRenderArgs.isLastPage) {
                            showMore();
                        }
                    });
                });

                observer.observe(sentinelCat);
                container.appendChild(contdiv);
            } else {
                const button = document.createElement('span');
                button.className = "noselect spanbutton";
                button.innerHTML = '<i class="fa fa-chevron-down"></i>' + getText('_GS_SEARCH_SHOWMORE');

                button.addEventListener('click', () => {
                    showMore();
                });

                container.appendChild(contdiv);
                container.appendChild(button);
            }
            return;
        }

        if (!autoInfinite) {
            if (isLastPage) {
                $(container).children('span.spanbutton').addClass("disabled");
            } else {
                $(container).children('span.spanbutton').removeClass("disabled");
            }
        }
        currentdata = hits;
        $(container).children('div.ais-gs-hits-cont').html(hits
            .map(
                hit =>
                    `<div class="ais-gs-hit" data-level="${hit.cat_level}">
    <a class="ais-gs-hit-link" href="${hit.link}">
		${showCatImage
                        ? (
                            '<div class="ais-gs-cat-image-content"><div class="ais-gs-cat-image-round" style="background-image: url(' + encodeURI(((hit.thumb_url && hit.thumb_url.startsWith('/SupplyImages/')) ? '' : '/SupplyImages/') + hit.thumb_url) + ');"></div></div><div class="ais-gs-cat-hit-content">')
                        : ''}
			<span class="ais-gs-cat-tree">${hit.category_tree ? hit.category_tree : '&nbsp;'}</span>
			<span class="ais-gs-cat-title">${instantsearch.highlight({ attribute: 'title', hit, highlightedTagName: 'em' })}</span>
			<!--<div class="clearfix"></div>-->
		${showCatImage ? '</div>' : ''}
	</a>
</div>`).join('')
        );
        $('.ais-gs-hit-link').on('click', clickhit);
        if (autoInfinite) {
            container.appendChild(sentinelCat);
        }
    }
);


/*   */

const dftSearchClient = algoliasearch(
    'Q077UDU0PB',
    'd86ed9829d2539e9430e6b3742dbfd75'
);

let lastSearchText = '';
const customSearchClient = {
    search(requests) {
        var hashValue = window.location.hash;
        // Rimuovi il carattere "#" se presente
        hashValue = hashValue.substring(1);
        let newSearchText = ((requests && requests[0] && requests[0].params && requests[0].params.query) || '') + (hashValue);
        let searchTextChanged = lastSearchText !== newSearchText;
        lastSearchText = newSearchText;


        // Se ho meno di 3 caratteri restituisco una risposta vuota senza effettuare la richiesta ad Algolia
        if (requests.every(({ params }) => (params.query || '').length < 3)) {
            return Promise.resolve({
                results: requests.map(() => ({
                    hits: [],
                    nbHits: 0,
                    hitsPerPage: 1,
                    nbPages: 0,
                    page: 0,
                    processingTimeMS: 0,
                    query: ''
                })),
            });
        }

        // Se ho i facet chiusi NON faccio passare le richieste di aggiornamento delle RefinementList per risparmiare operazioni
        if ($('.col-facets').attr('data-expanded') !== "1" && requests.length > 2) {
            //requests = [requests[0], requests[requests.length - 1]];
            requests.splice(2);
        }

        //return dftSearchClient.search(requests);

        if (searchTextChanged) {
            // Reset scroll prodotti, categorie e facet
            resetProdHitsScroll();
            resetCatHitsScroll();
            resetFacetsScroll();
        }

        if (cacheEnabled) {
            // Verifico se ho già la request in cache
            //debugger;
            const myResponses = Array(requests.length);
            const orig_request_index = {};

            let orig_i = 0;
            for (let i = 0; i < requests.length; i++) {
                let request = requests[i];
                orig_request_index[serialize(request)] = orig_i;

                let cachedResponse = cache_get(request);
                if (cachedResponse) {
                    // Ho già la risposta in cache: memorizzo e rimuovo la richiesta al server
                    myResponses[orig_i] = cachedResponse;
                    requests.splice(i, 1);
                    i--;
                }
                orig_i++;
            }

            if (requests.length > 0) {
                // Ci sono ancora delle richieste non servite dalla cache: effettuo la chiamata al server di Algolia e faccio merge
                return new Promise((resolve, reject) => {
                    dftSearchClient.search(requests)
                        .then((response) => {
                            // Risposta ok dal server
                            let serverResults = response.results.slice();
                            for (let i = 0; i < serverResults.length; i++) {
                                // aggiungo alla cache e ai risultati complessivi
                                cache_put(requests[i], serverResults[i]);
                                myResponses[orig_request_index[serialize(requests[i])]] = serverResults[i];
                            }
                            resolve({ results: myResponses });
                        })
                        .catch((error) => {
                            // Risposta con errore dal server => rigetto l'errore
                            reject(error);
                        });
                });
            }

            return Promise.resolve({
                results: myResponses,
            });

        } else {

            return dftSearchClient.search(requests);
        }
    }
};

const search = instantsearch({
    indexName: ais_gs_cfg.get().indexCat,
    searchClient: customSearchClient,
    stalledSearchDelay: 150,
});
search.use(analyticsMiddleware);
const renderMainStats = (renderOptions, isFirstRender) => {
    const { nbHits, processingTimeMS, query, widgetParams, page, hitsPerPage } = renderOptions;

    if (isFirstRender) {
        return;
    }

    widgetParams.container.innerHTML = `
<span class="ais-gs-stats-center">${getText('_GS_SEARCH_RESULTTITLE')}: <b><i>${query}</i></b></span>
<span class="ais-gs-stats-right ais-gs-spanlink"><i class="fa fa-times" id="ais-gs-search-close"></i></span>`;

    $("#ais-gs-mainstats").on('click', '#ais-gs-search-close', function (e) {
        hideAlgoliaSearchPanel();
    });
};

// Create the custom widget
const customMainStats = connectStats(renderMainStats);


const renderIndexStats = (renderOptions, isFirstRender, resultsContainer) => {
    const { nbHits, processingTimeMS, query, widgetParams, page, hitsPerPage } = renderOptions;

    if (isFirstRender) {
        return;
    }
    let shownHits = Math.min(nbHits, (page + 1) * hitsPerPage);

    if (shownHits === nbHits) {
        widgetParams.container.innerText = `(${nbHits})`;
    } else {
        widgetParams.container.innerText = `(${shownHits} / ${nbHits})`;
    }

    if (nbHits === 0 && $('body').attr('data-ais-needmorechar') !== '1') {
        $(resultsContainer).addClass('ais-gs-no-results');
    } else {
        $(resultsContainer).removeClass('ais-gs-no-results');
    }
};

const noresultHandler = [];

const renderIndexStatsSwipe = (renderOptions, isFirstRender) => {
    const { nbHits, widgetParams } = renderOptions;
    let bouncingTimeoutHandler;
    if (isFirstRender) {
        widgetParams.container.innerText = ``;
        return;
    }
    let swipeContainer = $(widgetParams.container.parentElement);

    if (bouncingTimeoutHandler) clearTimeout(bouncingTimeoutHandler);

    swipeContainer.removeClass("ais-gs-swipe-bouncing");
    if (nbHits > 0) {
        bouncingTimeoutHandler = setTimeout(() => {
            swipeContainer.addClass("ais-gs-swipe-bouncing");
        }, 10);
    }
    widgetParams.container.innerText = `(${nbHits})`;
};

// Create the custom widget
const customProdIndexStats = connectStats((renderOptions, isFirstRender) => { renderIndexStats(renderOptions, isFirstRender, document.querySelector('.col-prods .ais-gs-col-container')) });
const customCatIndexStats = connectStats((renderOptions, isFirstRender) => { renderIndexStats(renderOptions, isFirstRender, document.querySelector('.col-cats .ais-gs-col-container')) });
const customIndexStatsSwipe = connectStats(renderIndexStatsSwipe);

// custom `renderFn` to render the custom SearchBox widget
function renderSearchBox(SearchBoxRenderingOptions, isFirstRendering) {
    const { query, refine, isSearchStalled, clear } = SearchBoxRenderingOptions;
    var showReset = SearchBoxRenderingOptions.widgetParams.showReset;

    var $inputBox = $(txtkeywordClientID);
    var $resetSearch = $('.ais-SearchBox-reset');

    if (isFirstRendering) {
        if (showReset) {
            $resetSearch.on('click', clear);
        }

        $inputBox.on('keyup', function () {
            checkIfNeedMoreChars();
            showAlgoliaSearchPanel();
            if (!isFullScreenAis() && $(txtkeywordClientID).val() === "") {
                hideAlgoliaSearchPanel();
            }

            startDelayedSearch(refine, removeMultipleSpaces($(this).val() || ''));
        });
        $inputBox.on("click", function () {
            checkIfNeedMoreChars();
            showAlgoliaSearchPanel();
            if (isAlgoliaSearchHidden) {
                startDelayedSearch(refine, removeMultipleSpaces($(this).val() || ''));
            }
            //fix apple che zoomano a caso.
            $(this).blur();
            $(this).focus();
        });
        $inputBox.on("change", function () {
            checkIfNeedMoreChars();
        });
    } else {
        const $loader = $('#ais-gs-loader');
        if (isSearchStalled) {
            $loader.show();
        } else {
            $loader.hide();
        }
    }

    if (showReset && query.trim() && !isSearchStalled) {
        $resetSearch.show();
    } else {
        $resetSearch.hide();
    }

    checkIfNeedMoreChars();

}

const checkIfNeedMoreChars = (args) => {
    let path = window.location.pathname;
    let page = path.split("/").pop();
    let searchText = removeMultipleSpaces($(txtkeywordClientID).val() || ''); //advancedSearch.aspx
    if (searchText.length < 3 && page == 'advancedSearch.aspx') {
        $('body').attr('data-ais-needmorechar', '1');
    } else {
        $('body').attr('data-ais-needmorechar', '0');
    }
};

const removeMultipleSpaces = str => str.replace(/ +(?= )/g, '');


let delayedSearchHdl;
const startDelayedSearch = function (refine, val) {
    if ((val || '').length < 3) {
        $('.col-prods .ais-gs-col-container').removeClass('ais-gs-no-results');
        $('.col-cats .ais-gs-col-container').removeClass('ais-gs-no-results');
    }

    if (!isAlgoliaSearchHidden()) { // mobile
        if (delayedSearchHdl) clearTimeout(delayedSearchHdl);
        delayedSearchHdl = setTimeout(function () {
            refine(val);
        }, algoliaSearchDelay);
    }
};

let customSearchBox = connectSearchBox(renderSearchBox);

let isSearchingByVoice = false;
const renderVoiceSearch = (renderOptions, isFirstRender) => {
    const {
        isBrowserSupported,
        isListening,
        toggleListening,
        voiceListeningState,
        widgetParams,
    } = renderOptions;
    const {
        status,
        transcript,
        isSpeechFinal,
        errorCode,
    } = voiceListeningState;

    const $container = $(bAisVoiceSearchClientID);

    if (isFirstRender) {
        $('body').attr('data-ais-voicebtn', isBrowserSupported ? '1' : '0');
        if (!isBrowserSupported) {
            $container.addClass('ais-gs-voicesearch--disabled');
        } else {
            $('body').on('click', bAisVoiceSearchClientID, toggleListening);
        }
    }

    // Render the widget
    isListening
        ? $container.addClass('ais-gs-voicesearch--listening')
        : $container.removeClass('ais-gs-voicesearch--listening');

    // Start writing listened text
    if (isListening) {
        isSearchingByVoice = true;
    }

    // Write listened text
    if (isSearchingByVoice && (status === 'recognizing' || isSpeechFinal)) {
        var $inputBox = $(txtkeywordClientID);
        $inputBox.val(transcript);
    }

    // Stop writing listened text
    if (!isListening && isSpeechFinal) {
        isSearchingByVoice = false;
    }
};

const customVoiceSearch = connectVoiceSearch(
    renderVoiceSearch
);

const renderClearRefinements = (renderOptions, isFirstRender, refreshCounters) => {
    const { hasRefinements, refine, widgetParams } = renderOptions;

    if (isFirstRender) {
        const divclr = document.createElement('div');
        divclr.className = "ais-ClearRefinements";
        const button = document.createElement('button');
        button.innerHTML = '<span class="ais-gs-fa-clearfilter"><i class="fa fa-filter"></i><i class="fa fa-times"></i></span>';

        button.addEventListener('click', () => {
            refine();
        });
        divclr.appendChild(button);
        widgetParams.container.appendChild(divclr);
    }

    if (refreshCounters) refreshAppliedFiltersCounters();

    widgetParams.container.querySelector('button').className = "ais-ClearRefinements-button" + (hasRefinements ? '' : ' ais-ClearRefinements-button--disabled');
    widgetParams.container.querySelector('button').disabled = !hasRefinements;
};

// Create the custom widget
const customClearRefinements = (opt, facetSelector) =>
    connectClearRefinements((renderOptions, isFirstRender) =>
        renderClearRefinements(renderOptions, isFirstRender, facetSelector))(opt);
//const customClearRefinements_Brands = connectClearRefinements((renderOptions, isFirstRender) => renderClearRefinements(renderOptions, isFirstRender, '#facet-brand'));

const customClearRefinements_All_Widget = customClearRefinements(
    {
        container: document.querySelector('#ais-gs-clearallrefinements'),
        includedAttributes: ['categories', 'brand_name', 'new_exp_date', 'is_outlet'],
    },
    true // il refresh dei contatori lo fa scattare il clear globale
);

const customClearRefinements_Category_Widget = customClearRefinements(
    {
        container: document.querySelector('#facet-category .facet-filter-count-clearRef'),
        includedAttributes: ['categories'],
    },
    false // il refresh dei contatori lo fa scattare il clear globale
);

const customClearRefinements_Brand_Widget = customClearRefinements(
    {
        container: document.querySelector('#facet-brand .facet-filter-count-clearRef'),
        includedAttributes: ['brand_name'],
    },
    false // il refresh dei contatori lo fa scattare il clear globale
);

// Instantiate the custom widget
//search.addWidgets([
//	customClearRefinements({
//		container: document.querySelector('#ais-gs-clearallrefinements'),
//		includedAttributes: ['categories', 'brand_name'],
//	})
//]);

// CLEAR REFINEMENTS - ALL
//const clearAllRefinementsWidget =
//	clearRefinements({
//		container: '#ais-gs-clearallrefinements',
//		templates: {
//			resetLabel: '<span class="ais-gs-fa-clearfilter"><i class="fa fa-filter"></i><i class="fa fa-times"></i></span>',
//		},
//	});

const renderNumricMenuNewProducts = (renderOptions, isFirstRender) => {
    const { items, hasNoResults, refine, widgetParams } = renderOptions;

    const attribute = widgetParams.attribute;
    widgetParams.container.innerHTML =
        items.map(
            item => item.label === 'new' ? `
<label  class="gs-toggle-wrapper">
	<input 
		id="chkNewProd"
		type='checkbox'
		name="${attribute}"
		value="${item.value}"
		${item.isRefined ? 'checked="checked"' : ''}
	/>
	<label for='chkNewProd' class="gs-toggle-pseudochk">
		<div class='gs-toggle-knob'></div>
	</label>

	<span class="gs-toggle-label${item.isRefined ? ' gs-toggle-label--checked"' : ''}">${getText('_GS_SEARCH_NEWPRODONLY')}</span>
	
</label>` : '').join('');

    /*	const attribute = widgetParams.attribute;
        widgetParams.container.innerHTML = 
          items.map(
                    item => item.label === 'new' ? `
    <label class="gs-toggle-wrapper">
        <input
        type="checkbox"
        name="${attribute}"
        value="${item.value}"
        ${item.isRefined ? 'checked="checked"' : ''}
        />
        <span class="gs-toggle-label ${item.isRefined ? 'gs-toggle-label--checked"' : ''} ">${getText('_GS_SEARCH_NEWPRODONLY')}</span>
    	
    </label>` : '').join('');*/

    //	widgetParams.container.innerHTML = `
    //<label class="ais-RefinementList-label">
    //    <input
    //        type="checkbox"
    //        name="new_exp_date"
    //        value="${item.value}"
    //        ${item.isRefined ? 'checked' : ''}
    //    />
    //    ${item.label}
    //</label>`;
    // todo: gestire il map sugli item ma prendere solo quello con "start" valorizzato (novità)
    // poi passare nel "change" e verificare se è selezionato oppure no...
    // {"start":"20200107"}
    if (isFirstRender) {
        $(widgetParams.container)
            .on('change',
                'input[type="checkbox"][name="new_exp_date"]',
                (event) => {
                    refine(event.target.checked ? event.target.value : '%7B%7D');
                });
    }
};

const customNumericMenuNewProducts = connectNumericMenu(
    renderNumricMenuNewProducts
);

const customNumericMenu_Widget = customNumericMenuNewProducts({
    container: document.querySelector('#facet-new-products .facet-list'),
    attribute: 'new_exp_date',
    items: [
        { label: 'all' },
        { label: 'new', start: currDateInt },
    ],
})

//const newProducts = numericMenu({
//	container: '#facet-new-products .facet-list',
//	attribute: 'new_exp_date',
//	items: [
//		{ label: 'Tutti' },
//		{ label: 'Novità', start: currDate },
//		{ label: 'Vecchi', end: currDate - 1 }
//	],
//});
//const newProducts = toggleRefinement({
//	container: '#facet-new-products .facet-list',
//	attribute: 'new_exp_date',
//	on: '20200531',
//	templates: {
//		labelText: `
//	${isRefined ? 'Tutti' : 'Novità'} ({{ count }})
//`,
//	}
//});
// OUTLET FILTER
const renderNumricMenuOutletProducts = (renderOptions, isFirstRender) => {
    const { items, hasNoResults, refine, widgetParams } = renderOptions;

    const attribute = widgetParams.attribute;
    widgetParams.container.innerHTML =
        items.map(
            item => item.label === 'outlet' ? `
<label  class="gs-toggle-wrapper">
	<input 
		id="chkOutletProd"
		type='checkbox'
		name="${attribute}"
		value="${item.value}"
		${item.isRefined ? 'checked="checked"' : ''}
	/>
	<label for='chkOutletProd' class="gs-toggle-pseudochk">
		<div class='gs-toggle-knob'></div>
	</label>

	<span class="gs-toggle-label${item.isRefined ? ' gs-toggle-label--checked"' : ''}">${getText('_GS_SEARCH_OUTLETPRODONLY')}</span>
	
</label>` : '').join('');
    if (isFirstRender) {
        $(widgetParams.container)
            .on('change',
                'input[type="checkbox"][name="is_outlet"]',
                (event) => {
                    refine(event.target.checked ? event.target.value : '%7B%7D');
                });
    }
};

const customNumericMenuOutletProducts = connectNumericMenu(
    renderNumricMenuOutletProducts
);

const customNumericMenuOutlet_Widget = customNumericMenuOutletProducts({
    container: document.querySelector('#facet-outlet .facet-list'),
    attribute: 'is_outlet',
    items: [
        { label: 'all' },
        { label: 'outlet', start: 1 },
    ],
})


const catRefinementListWidget = refinementList({
    container: '#facet-category .facet-list',
    attribute: 'categories',
    limit: 5,
    showMore: true,
    sortBy: ['count:desc', 'name:asc'],
    cssClasses: {
        root: 'ais-gs-RefinementList',
        list: 'ais-gs-RefinementList-list',
        item: 'ais-gs-RefinementList-item',
        checkbox: 'ais-gs-RefinementList-checkbox',
        labelText: 'ais-gs-RefinementList-labelText',
        count: 'ais-gs-RefinementList-count',
    },
    templates: {
        showMoreText: `
{{#isShowingMore}}
<i class="fa fa-chevron-up"></i> ${getText('_GS_SEARCH_SHOWLESS')}
{{/isShowingMore}}
{{^isShowingMore}}
<i class="fa fa-chevron-down"></i> ${getText('_GS_SEARCH_SHOWMORE')}
{{/isShowingMore}}
`,
    },
});

// CURRENT REFINEMENTS - CATEGORIES
const catCurrentRefinementsWidget = currentRefinements({
    container: '#facet-category .facet-current-refinements',
    includedAttributes: ['categories'],
});

//// CLEAR REFINEMENTS - CATEGORIES
//const catClearRefinementsWidget = clearRefinements({
//	container: '#facet-category .facet-filter-count-clearRef',
//	includedAttributes: ['categories'],
//	templates: {
//		resetLabel: '<span class="ais-gs-fa-clearfilter"><i class="fa fa-filter"></i><i class="fa fa-times"></i></span>',
//	},
//});


// REFINEMENT LIST - BRANDS
const brandRefinementListWidget = refinementList({
    container: '#facet-brand .facet-list',
    attribute: 'brand_name',
    limit: 5,
    showMore: true,
    sortBy: ['count:desc', 'name:asc'],
    cssClasses: {
        root: 'ais-gs-RefinementList',
        list: 'ais-gs-RefinementList-list',
        item: 'ais-gs-RefinementList-item',
        checkbox: 'ais-gs-RefinementList-checkbox',
        labelText: 'ais-gs-RefinementList-labelText',
        count: 'ais-gs-RefinementList-count',
    },
    //transformItems(items) {
    //	return [...items, {
    //		count: 0,
    //		isRefined: true,
    //		label: 'Diego',
    //		value: 'Diego',
    //		highlighted: 'Di<em>eg</em>o',
    //		cssClasees: {}
    //	}];
    //},
    templates: {
        showMoreText: `
{{#isShowingMore}}
<i class="fa fa-chevron-up"></i> ${getText('_GS_SEARCH_SHOWLESS')}
{{/isShowingMore}}
{{^isShowingMore}}
<i class="fa fa-chevron-down"></i> ${getText('_GS_SEARCH_SHOWMORE')}
{{/isShowingMore}}
`,
    },
});

// CURRENT REFINEMENTS - BRANDS
const brandCurrentRefinementsWidget = currentRefinements({
    container: '#facet-brand .facet-current-refinements',
    includedAttributes: ['brand_name'],
});

//// CLEAR REFINEMENTS - BRANDS
//const brandClearRefinementsWidget = clearRefinements({
//	container: '#facet-brand .facet-filter-count-clearRef',
//	includedAttributes: ['brand_name'],
//	templates: {
//		resetLabel: '<span class="ais-gs-fa-clearfilter"><i class="fa fa-filter"></i><i class="fa fa-times"></i></span>',
//	},
//});


// mount widget on main index
search.addWidgets([
    // MAIN STATS
    customMainStats({
        container: document.querySelector('#ais-gs-mainstats')
    }),
    // VOICE SEARCH
    customVoiceSearch({
        searchAsYouSpeak: false,
        language: ais_gs_cfg.get().voiceSearchLang,
    }),
    // SEARCH BOX
    customSearchBox({
        containerNode: $('#searchpnl'),
        showReset: false,
    }),
    // INFINITE HITS
    infiniteHitsCat({
        container: document.querySelector('#hits-cat'),
        escapeHTML: false,
    }),
    // STATS
    customCatIndexStats({
        container: document.querySelector('.ais-gs-stats-cat')
    }),
    // STATS (SWIPE)
    customIndexStatsSwipe({
        container: document.querySelector('.col-prods .ais-gs-col-swipe-count')
    })
]
);





search.addWidgets([
    index({ indexName: ais_gs_cfg.get().indexProd })
        .addWidgets([
            // STATS
            customProdIndexStats({
                container: document.querySelector('.ais-gs-stats-prod'),
            }),
            // STATS ON PROD TAB FLAP
            customIndexStatsSwipe({
                container: document.querySelector('.col-cats .ais-gs-col-swipe-count')
            }),

            // REFINEMENT LIST - CATEGORIES
            catRefinementListWidget,
            // CURRENT REFINEMENTS - CATEGORIES
            catCurrentRefinementsWidget,
            // CLEAR REFINEMENTS - CATEGORIES
            customClearRefinements_Category_Widget,
            //catClearRefinementsWidget,

            // REFINEMENT LIST - BRANDS
            brandRefinementListWidget,
            // CURRENT REFINEMENTS - BRANDS
            brandCurrentRefinementsWidget,
            // CLEAR REFINEMENTS - BRANDS
            customClearRefinements_Brand_Widget,
            //brandClearRefinementsWidget,


            // NUMERIC MENU
            customNumericMenu_Widget,

            // OUTLET MENU
            customNumericMenuOutlet_Widget,

            // CLEAR REFINEMENTS - ALL
            customClearRefinements_All_Widget,
            //clearAllRefinementsWidget,

            // INFINITE HITS
            infiniteHitsProd({
                container: document.querySelector('#hits-prod'),
                escapeHTML: false,
            }),
        ])
]);

const preventPostBack = (e) => {
    e.preventDefault();
    e.stopPropagation();
};

$("body")
    .off("click", ".ais-gs-panel-body :button, .ais-gs-panel-body :button *", preventPostBack)
    .on("click", ".ais-gs-panel-body :button, .ais-gs-panel-body :button *", preventPostBack);

search.start();

